* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

/*
 * -- BASE STYLES --
 * Most of these are inherited from Base, but I want to change a few.
 */
body {
    line-height: 1.7em;
    color: #7f8c8d;
    background: #f1f3f5;
    font-size: 13px;
}

h1,
h2,
h3,
h4,
h5,
h6,
label {
    color: #34495e;
}

.pure-img-responsive {
    max-width: 100%;
    height: auto;
}

/*
 * -- LAYOUT STYLES --
 * These are some useful classes which I will need
 */
.l-box {
    padding: .5em;
}

.l-box-lrg {
    padding: 2em;
    border-bottom: 1px solid rgba(0,0,0,0.1);
}

.is-center {
    text-align: center;
}



/*
 * -- PURE FORM STYLES --
 * Style the form inputs and labels
 */
.pure-form label {
    margin: 1em 0 0;
    font-weight: bold;
    font-size: 100%;
}

.pure-form input[type] {
    border: 2px solid #ddd;
    box-shadow: none;
    font-size: 100%;
    width: 100%;
    margin-bottom: 1em;
}

/*
 * -- PURE BUTTON STYLES --
 * I want my pure-button elements to look a little different
 */
.pure-button {
    background-color: #1f8dd6;
    color: white;
    padding: 0.5em 2em;
    border-radius: 5px;
}

a.pure-button-primary {
    background: white;
    color: #1f8dd6;
    border-radius: 5px;
    font-size: 120%;
}


/*
 * -- MENU STYLES --
 * I want to customize how my .pure-menu looks at the top of the page
 */

.home-menu {
    padding: 0.5em;
    text-align: center;
    box-shadow: 0 1px 1px rgba(0,0,0, 0.10);
}
.home-menu {
    background: #2a3f54;
}
.pure-menu.pure-menu-fixed {
    /* Fixed menus normally have a border at the bottom. */
    border-bottom: none;
    /* I need a higher z-index here because of the scroll-over effect. */
    z-index: 4;
}

.home-menu .pure-menu-heading {
    color: white;
    font-weight: 400;
    font-size: 120%;
}

.home-menu .pure-menu-selected a.pure-menu-link,
.home-menu .pure-menu-selected a {
    color: white;
}

.home-menu a {
    color: #6FBEF3;
}
.home-menu li a:hover,
.home-menu li a:focus {
    background: none;
    border: none;
    color: #AECFE5;
}


/*
 * -- SPLASH STYLES --
 * This is the blue top section that appears on the page.
 */

.splash-container {
    background: #2a3f54;
    background-image: url(../images/mini-images.png);
    z-index: 1;
    overflow: hidden;
    /* The following styles are required for the "scroll-over" effect */
    width: 100%;
    height: 88%;
    top: 0;
    left: 0;
    position: fixed !important;
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
}

.splash-landing-container {
    background: #2a3f54;
    background-image: url(../images/product-desktop.png);
    z-index: 1;
    overflow: hidden;
    /* The following styles are required for the "scroll-over" effect */
    width: 100%;
    height: 88%;
    top: 0;
    left: 0;
    position: fixed !important;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}


.splash {
    /* absolute center .splash within .splash-container */
    margin-top: auto;
    margin-bottom: auto;
    position: absolute;
    height: 50%;
    top: 100px; left: 0; bottom: 0; right: 0;
    text-align: center;
    //text-transform: uppercase;
}
.splash-landing {
    /* absolute center .splash within .splash-container */
    margin-top: auto;
    margin-bottom: auto;
    position: absolute;
    height: 50%;
    top: 100px; left: 0; bottom: 0; right: 0;
    text-align: center;
    //text-transform: uppercase;
}
.splash-shadow {
    background: -webkit-linear-gradient(rgba(0, 0, 0, 0.41), #080A21) !important;position: fixed;width: 100%;height: 100%;
}
/* This is the main heading that appears on the blue section */
.splash-head {
    font-size: 20px;
    font-weight: bold;
    color: rgba(255, 255, 255, 0.94);
    font-family: 'Lato', sans-serif;
    /*border: 3px solid rgba(45, 62, 80, 0.66);*/
    /*padding: 1em 1.6em;*/
    font-weight: 100;
    border-radius: 5px;
    /*line-height: 1em;*/
    /*background: rgba(45, 62, 80, .6);*/
}

/* This is the subheading that appears on the blue section */
.splash-subhead {
    color: white;
    font-size: 110%;
    letter-spacing: 0.05em;
    text-shadow: 2px 2px 3px #000000;
}

/*
 * -- CONTENT STYLES --
 * This represents the content area (everything below the blue section)
 */
.content-wrapper {
    /* These styles are required for the "scroll-over" effect */
    position: absolute;
    top: 87%;
    width: 100%;
    min-height: 12%;
    z-index: 2;
    background: white;
    box-shadow: 0px -7px 50px 3px rgba(0, 0, 0, .6);
}

.content-wrapper-min-top {
    /* These styles are required for the "scroll-over" effect */
    position: absolute;
    top: 7%;
    width: 100%;
    min-height: 93%;
    z-index: 2;
    background: white;
    box-shadow: 0px -7px 50px 3px rgba(0, 0, 0, .6);
}

/* We want to give the content area some more padding */
.content {
    padding: 1em 1em 3em;
}

/* This is the class used for the main content headers (<h2>) */
.content-head {
    font-weight: 400;
    //text-transform: uppercase;
    letter-spacing: 0.02em;
    margin: 2em 0 1em;
}

p.content-head {
    font-size: 20px;
}

/* This is a modifier class used when the content-head is inside a ribbon */
.content-head-ribbon {
    color: white;
}

/* This is the class used for the content sub-headers (<h3>) */
.content-subhead {
    color: #1f8dd6;
}
    .content-subhead i {
        margin-right: 7px;
    }

/* This is the class used for the dark-background areas. */
.ribbon {
    background: #2a3f54;
    color: #aaa;
}

/* This is the class used for the footer */
.footer {
    background: #111;
    bottom: 0;
    width: 100%;
}

.footer-absolute {
    position: absolute;
}

/*
 * -- TABLET (AND UP) MEDIA QUERIES --
 * On tablets and other medium-sized devices, we want to customize some
 * of the mobile styles.
 */
@media (min-width: 48em) {

    /* We increase the body font size */
    body {
        font-size: 16px;
    }

    /* We can align the menu header to the left, but float the
    menu items to the right. */
    .home-menu {
        text-align: left;
    }
        .home-menu ul {
            float: right;
        }

    /* We increase the height of the splash-container */
/*    .splash-container {
        height: 500px;
    }*/

    /* We decrease the width of the .splash, since we have more width
    to work with */
    .splash {
        height: 50%;
    }

    .splash-head {
        font-size: 180%;
    }


    /* We remove the border-separator assigned to .l-box-lrg */
    .l-box-lrg {
        border: none;
    }

}

/*
 * -- DESKTOP (AND UP) MEDIA QUERIES --
 * On desktops and other large devices, we want to over-ride some
 * of the mobile and tablet styles.
 */
@media (min-width: 78em) {
    /* We increase the header font size even more */
    .splash-head {
        font-size: 200%;
    }
}

.text-transform-none {
    text-transform: none;
}

.vertical-align-bottom {
    vertical-align: bottom;
}

hr {
    width: 10%;
    border-width: 2px;
    border-color: #3581ff;
}

.bg-quote {
    background-image: url(../images/captura.png);
    background-repeat: no-repeat;
    background-size: cover;
}

.overflow-hidden {
    overflow: hidden;
}

.bg-img {
    height: 360px;
    background-size: auto;
    background-repeat: no-repeat;
}

.bg-img-right {
    background-position: right;
}

.bg-img-pdf {
    background-position: left;
    background-size: cover;
    background-image: url(../images/pdf.png);
}

.bg-img-xml {
    background-size: cover;
    background-image: url(../images/visor_xml.png);
    background-position: center;
}

.bg-img-contrasenia {
    background-image: url(../images/contrasenia.gif);
    background-size: cover;
    background-position: center bottom;
}

.bg-img-fiel {
    background-image: url(../images/fiel.gif);
    background-size: cover;
    background-position: center;
}

.title-description {
    font-size: 20px;
    color: #34495e;
}

.color-white-subhead {
    color: rgba(255, 255, 255, 0.84);
}

p.color-white-subhead.size-head {
    font-size: 20px;
    font-weight: 100;
}

a:visited {
    /*color: #265778;*/
}

a {
    color: #3b8bba;
    text-decoration: none;
}
.pure-table {
    width: 100%;
}
.green {
    color: #009688;
}
.computer-check-container {
    position: relative;
    border: solid 1px rgba(0, 0, 0, .1);
    margin-bottom: 10px;
}
.computer-i-check.fa-square-o {
    animation-name: changeColor;
    animation-timing-function: linear;
    animation-direction: alternate-reverse;
    animation-duration: 3s;
    animation-iteration-count: infinite;
}
.computer-i-check,
.computer-input-check {
    position: absolute;
    width: 30px;
    height: 30px;
    right: 0;
    top: 0;
}
.computer-input-check {
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
}

@keyframes changeColor {
    from {color: #f1f1f1;}
    to {color: #009688;}
}
.auto-margin {
    margin: auto;
}
.justify {
    text-align: justify;
}
.justify-padding {
    text-align: justify;
    padding: 0px 15px;
}

.social {
    font-size: 18px;
    position: fixed;
    text-align: center;
    padding: 0;
    top: 10%;
    z-index: inherit;
    .facebook {
        background: #3B5998;
        a {
            display: block;
            color: #fff;
            display: block;
            width: 100%;
            position: relative;
            margin: 0;
            padding: 6px 6px
        }
    }
    .linkedin {
        background: #0e76a8;
        a {
            display: block;
            color: #fff;
            display: block;
            width: 100%;
            position: relative;
            margin: 0;
            padding: 6px 6px
        }
    }
}



@media only screen and (max-width : 480px) {
    .social {
        font-size: 14px;
    }
    .facebook a {
        padding: 6px 6px;
    }
    .facebook a {
        padding: 6px 6px;
    }
}
.thanks-flaticon {
    font-family: 'Lato';
    font-size: 12px;
}

.btn-download {
  background-color: #0099ff;
  font-size: 1.2em;
  padding: 1em 2em;
}
