.thumbnail {
  padding: 0 !important;
  border: 2px solid #dadada !important;
  cursor: pointer;
}

.thumbnail .caption {
  background-color: #dadada !important;
}

.thumbnail>img {
  width: 100px !important;
}

.fa-container{
  text-align: center;position: absolute;right: 20px;top: 5px;line-height: 22px;
}
.golden-color{
  color: #d6b818;
}
  
.text {
  background: #FFF;
  padding: 10px;
}
  
.text p {
  margin: 0px;
  font-size: 12px;
}

.text p#date{
  font-size: 10px;
  width: 100%;
  text-align: right;
}

input.form-control {
  margin-right: 20px;
}

hr.large{
  border-top: 2px solid #eee !important;
  width: 100% !important;
}

.panel{
  margin-top: -15px !important;
}
table.detail-log{
  font-size: initial !important;
}

.list-detail-log{
  height: 398px !important;
  overflow: overlay !important;
}

.margin-0{
  margin: 0 !important;
}

.item-active{
  background-color: lightgray !important;
}

.status-success{
  color: #18ab1a;
}

.status-warning{
  color: #f79e06;
}

.status-info{
  color: #4391c7;
}

.status-danger{
  color: #f72e2a;
}